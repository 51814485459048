import { createSharedComposable } from '@vueuse/core'
import { addMilliseconds, isAfter } from 'date-fns'

import { contentDisplaySettingsSchema } from './index.types'
import type { ContentDisplaySettings } from './index.types'

type UseFeatureFlagData = {
  featureFlags: ContentDisplaySettings
}

const FEATURE_FLAG_LOCAL_STORAGE_KEY = 'app:featureFlags'

export type FeatureFlags = ContentDisplaySettings

function loadFromLocalStorage(): ContentDisplaySettings {
  const localStorageFeatureFlag =
    localStorage.getItem(FEATURE_FLAG_LOCAL_STORAGE_KEY) ?? ''
  try {
    const localFeatureFlag = JSON.parse(localStorageFeatureFlag)
    return parseFeatureFlagsOrDefault(localFeatureFlag)
  } catch {
    return toRaw(useAppConfig().defaultFeatureFlags)
  }
}

function saveToLocalStorage(featureFlags: ContentDisplaySettings) {
  const featureFlagStr = JSON.stringify(
    parseFeatureFlagsOrDefault(featureFlags)
  )
  localStorage.setItem(FEATURE_FLAG_LOCAL_STORAGE_KEY, featureFlagStr)
}

function parseFeatureFlagsOrDefault(values: unknown): ContentDisplaySettings {
  const parsedData = contentDisplaySettingsSchema
    .passthrough()
    .safeParse(values)
  if (!parsedData.success) {
    console.warn('Invalid Feature Flags', parsedData.error)
    return toRaw(useAppConfig().defaultFeatureFlags)
  }
  return parsedData.data
}

/**
 * Waits for the assertive yield data to become available.
 * Polls every `sleepInterval` until `maxTimeoutMs`
 */
async function waitForAssertiveYield(
  maxTimeoutMs: number,
  sleepInterval: number
) {
  const sleepFn = (timeoutMs: number) =>
    new Promise((resolver) => setTimeout(resolver, timeoutMs))

  const timeout = addMilliseconds(new Date(), maxTimeoutMs)
  while (!window.aYield) {
    console.debug('Waiting for features')
    if (isAfter(new Date(), timeout)) throw new Error('timeout reached')
    await sleepFn(sleepInterval)
  }
  return window.aYield
}

/**
 * Shared composable that returns current feature flags.
 * On mount, updates local storage with new data from Assertive Yield if available.
 */
export const useFeatureFlags = createSharedComposable(
  (): UseFeatureFlagData => {
    const existingFeatureFlags = loadFromLocalStorage()

    async function updateFeatureFlagsFromAssertiveYield() {
      const maxTimeoutMs = 30_000
      const sleepInterval = 250
      try {
        const assertiveYield = await waitForAssertiveYield(
          maxTimeoutMs,
          sleepInterval
        )

        // Only update local storage when the timestamp is newer than the cache
        const currentFeatureTime = new Date(existingFeatureFlags.timestamp ?? 0)
        const newFeatureTime = new Date(
          assertiveYield.featureFlags?.timestamp ?? 0
        )
        if (newFeatureTime <= currentFeatureTime) {
          console.debug('Discarding: features are not new.')
          return
        }

        console.debug(
          'Persisting new feature flags:',
          assertiveYield.featureFlags
        )

        saveToLocalStorage(
          parseFeatureFlagsOrDefault(assertiveYield.featureFlags)
        )
      } catch {
        console.warn('Feature flags not retrieved before timeout.')
      }
    }

    updateFeatureFlagsFromAssertiveYield()
    return { featureFlags: existingFeatureFlags }
  }
)
