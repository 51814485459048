<script setup lang="ts">
  import { computed, nextTick, onMounted, ref } from 'vue'
  import { useAppConfig } from 'nuxt/app'
  import type { NtpAdEvent } from '../../composables/analytics/types'
  import {
    getAssertiveYieldCustomLayout,
    type AyLogImpressionEvent,
  } from '../../composables/third-party/useAssertiveYield'
  import useOutbrainAds, {
    type OutbrainElementData,
  } from '../../composables/ad-ops/useOutbrainAds'
  import useAdFunctions from '../../composables/ad-ops/useAdFunctions'

  interface OutbrainWidgetAttributes {
    'data-ob-portalUrl': string
    'data-ob-language': string
    'data-widget-id': string
    'data-external-id': string
    'data-ob-installation-key': string
    'data-external-secondary-id': string
    'data-render-registered': boolean
  }

  /**
   * Props for the Outbrain component
   * @property placement - The placement identifier for the ad
   * @property widgetId - The Outbrain widget ID
   * @property tilePosition - Optional position of the tile in a list
   * @property adHeight - Optional height of the ad in pixels
   * @property adWidth - Optional width of the ad in pixels
   * @property visibilityThresholdInPercentage - Optional percentage of the element that must be visible
   * @property visibilityTimeoutInMs - Optional timeout in milliseconds before rendering when visible
   */
  export interface OutbrainProps {
    placement: string
    widgetId: string
    tilePosition?: number
    adHeight?: number
    adWidth?: number
    visibilityThresholdInPercentage?: number
    visibilityTimeoutInMs?: number
  }

  const props = withDefaults(defineProps<OutbrainProps>(), {
    adHeight: 304,
    adWidth: 300,
    visibilityThresholdInPercentage: 0, // 0 means no threshold, always trigger callback
    visibilityTimeoutInMs: 0, // 0 means no timeout
    tilePosition: -1,
  })

  const { outbrain } = useAppConfig()
  const { adClicked, adRendered, renderWhenCurrentElementIsVisible } =
    useAdFunctions()

  const { callRenderResearchWidgets, triggerOutbrainRenderedEvent } =
    useOutbrainAds()

  const widgetWrapperEl = useTemplateRef<HTMLElement | null>('widgetWrapperEl')
  const adBrand = ref<string>('')

  const widgetAttributes = computed<OutbrainWidgetAttributes>(() => {
    const widgetAttributes = {
      'data-ob-portalUrl': '',
      'data-ob-language': 'en',
      'data-widget-id': props.widgetId,
      'data-external-id': '',
      'data-ob-installation-key': outbrain?.installationKey || '',
      'data-external-secondary-id': '',
      'data-render-registered': false,
    }

    if (import.meta.client) {
      // Creates a random unique id for each ad.
      const secondaryId = crypto?.randomUUID
        ? `${Date.now()}-${crypto.randomUUID().slice(0, 8)}`
        : `${Date.now()}-${Math.random().toString(36).substring(2, 10)}`

      widgetAttributes[`data-ob-portalUrl`] = getAssertiveYieldCustomLayout()
      widgetAttributes[`data-external-id`] = window.location.host
      widgetAttributes[`data-external-secondary-id`] = secondaryId
    }

    return widgetAttributes
  })

  function setAdBrand(element: Element) {
    adBrand.value = element?.querySelector('.ob-rec-source')?.textContent || ''
  }

  function getEventData(): NtpAdEvent {
    const eventData: NtpAdEvent = {
      ntp_tile_position: props.tilePosition,
      ntp_ad_provider: 'outbrain',
      ntp_creative_id: props.widgetId,
      ntp_placement_name: props.placement,
      ntp_ad_brand: adBrand.value,
    }

    return eventData
  }

  function sendAdRenderedEventAndLogImpressionToAY() {
    const ayImpressionData: Partial<AyLogImpressionEvent> = {
      slotId: props.widgetId,
      mediaType: 'native',
      creative_width: props?.adWidth || 300,
      creative_height: props?.adHeight || 304,
      preBidWon: false,
      highestPreBid_partner: '',
      timeToRespond: null,
      adUnitPath: props.placement,
      impression_time: Date.now(),
    }

    adRendered(getEventData(), ayImpressionData)
  }

  function handleAdClick() {
    adClicked(getEventData(), props.widgetId)
  }

  function attachClickEventToAnchorTags(element: Element) {
    const anchorTag = element.querySelector('a')

    if (anchorTag && !anchorTag.getAttribute('data-click-registered')) {
      anchorTag.setAttribute('data-click-registered', 'true')
      anchorTag.addEventListener('click', handleAdClick)
    }
  }

  function detachClickEventFromAnchorTags(element: Element) {
    // Find all anchor tags with our registered click handlers and remove the listeners
    const registeredAnchors = element.querySelectorAll(
      'a[data-click-registered="true"]'
    )
    registeredAnchors.forEach((anchor) => {
      anchor.removeEventListener('click', handleAdClick)
    })
  }

  function handleOutbrainRenderedEvent(data: OutbrainElementData) {
    // Outbrain Render Event Handler
    //
    // The 'rendered' event fires multiple times during the ad rendering process:
    // 1. Initial fires: Returns wrapper element only (no actual ad content)
    // 2. DOM attachment: Element may not be in DOM tree yet (no parentElement)
    // 3. Final render: Ad is fully rendered and attached to DOM
    //
    // Implementation Strategy:
    // - Use parentElement presence as render completion indicator
    // - Verify correct placement via expected class name
    // - Track render status to prevent duplicate processing
    // - Process only first successful render
    // - Mark as rendered after processing

    const element: Element | null = data.element
    const parentElement: HTMLElement | null =
      element?.parentElement?.parentElement?.parentElement || null
    const isRendered =
      parentElement?.getAttribute('data-render-registered') === 'true'
    const hasExpectedClass = parentElement?.classList.contains(props.placement)

    if (parentElement && !isRendered && hasExpectedClass) {
      // Set ad brand for analytics
      setAdBrand(element)

      sendAdRenderedEventAndLogImpressionToAY()
      attachClickEventToAnchorTags(data.element)

      parentElement.setAttribute('data-render-registered', 'true')
    }
  }

  const renderOutbrainElement = ref(false)

  function renderOutbrainAd() {
    renderOutbrainElement.value = true

    nextTick(() => {
      if (
        !widgetWrapperEl.value ||
        widgetWrapperEl.value?.getAttribute('data-render-registered')
      )
        return

      callRenderResearchWidgets()

      triggerOutbrainRenderedEvent(props.widgetId, handleOutbrainRenderedEvent)
    })
  }

  onMounted(() => {
    renderWhenCurrentElementIsVisible(
      widgetWrapperEl.value,
      props.visibilityTimeoutInMs,
      props.visibilityThresholdInPercentage,
      renderOutbrainAd
    )
  })

  onUnmounted(() => {
    if (widgetWrapperEl.value) {
      detachClickEventFromAnchorTags(widgetWrapperEl.value)
    }
  })
</script>

<template>
  <div
    ref="widgetWrapperEl"
    :class="placement"
    :style="{ width: `${adWidth}px`, height: `${props.adHeight}px` }"
  >
    <div
      v-if="renderOutbrainElement"
      class="OUTBRAIN outbrain-homepage-ad"
      :class="placement"
      :style="{ width: `${adWidth}px`, height: `${props.adHeight}px` }"
      v-bind="widgetAttributes"
    />
  </div>
</template>
