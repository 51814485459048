import type { FeatureFlags } from '../common/composables/feature-flags/useFeatureFlags'

export const featureFlags: FeatureFlags = {
  timestamp: '1980-08-29T19:59:44.750Z',
  name: 'msn_inspirational_default',
  homePage: {
    doodle: 'off', // on or off or random
    design: 'msn', // google or msn
    searchboxDesign: 'v2', // default or v2 or v2-big-yolo or v3
  },
  sortNewsBy: 'clusterSize', // publishedDate or clusterSize
  summaryPage: {
    design: 'msn', // google or msn
    siderail: {
      position: 'full-height', // full-height or below-header
      topClass: 'on_article_top_siderail',
      middleClass: '',
      bottomClass: '',
      topArticleGroup: {
        design: 'google', // google or msn
        articlesPerGroup: 3, // number of articles being displayed
        type: 'related-stories', // top-stories or related-stories
      },
      bottomArticleGroup: {
        design: 'google', // google or msn
        articlesPerGroup: 3, // number of articles being displayed
        type: 'related-stories', // top-stories or related-stories
      },
    },
    content: {
      aboveTitleClass: 'on_article_hero',
      topClass: '',
      belowImageClass: 'below-image',
      middleClass: 'on_article_incontent',
      bottomContentAboveButtonClass: '',
      bottomContentBelowButtonClass: 'on_article_belowcontent_native',
    },
    showArticleImage: true,
    bottomClass: '',
  },
  userSettings: {
    position: 'off', // off or footer
    defaultSettings: {
      theme: 'auto', // light or dark or auto
      articleSummary: true,
    },
    enabledSettings: {
      articleSummary: false,
    },
  },
  sponsoredTiles: {
    provider: 'affinity', // affinity or admarketplace or off
    tilesCount: 5, // number
  },
  enableArticleReordering: false,
  enableLocalNewsSearch: true,
  adsDynamicFloorPricing: {
    isEnabled: false,
    excludePlacements: [],
  },
  layoutSkeleton: {
    name: 'msn_inspirational_default',
    section: [
      {
        name: 'First Section with no Repeat',
        background: 'secondary',
        titles: [
          { y: 1, type: 'slider' },
          { y: 1, type: 'title', repeat: true },
          { y: 2, type: 'article-group' },
          { y: 2, type: 'title', repeat: true },
          { y: 3, type: 'title', repeat: true },
        ],
        ads: [
          {
            y: 1,
            x: -1,
            classes: 'on_newtab_r1_topright no-padding',
            props: {
              adType: 'native-outbrain',
              widgetId: 'AR_36',
              placement: 'on_newtab_r1_topright',
            },
          },
          {
            y: 1,
            x: -3,
            classes: 'on_newtab_r1_c2 no-padding',
            props: {
              adType: 'native-outbrain',
              widgetId: 'AR_35',
              placement: 'on_newtab_r1_c2',
            },
            onlyWhenGridIsGreaterThan: 4,
          },
          {
            y: 2,
            x: 1,
            classes: 'on_newtab_r2_c1 no-padding',
            props: {
              adType: 'native-outbrain',
              widgetId: 'AR_37',
              placement: 'on_newtab_r2_c1',
            },
            onlyWhenGridIsGreaterThan: 2,
          },
          {
            y: 3,
            x: -1,
            classes: 'on_newtab_r3_right no-padding',
            props: {},
            onlyWhenGridIsGreaterThan: 2,
          },
        ],
      },
      {
        name: 'Second Section',
        padded: false,
        titles: [{ y: 1, type: 'video', repeat: true }],
        ads: [
          {
            y: 1,
            x: 1,
            classes: 'on_newtab_r4_c1 no-padding',
            props: {},
          },
          {
            y: 1,
            x: -1,
            classes: 'on_newtab_r4_right no-padding',
            props: {},
          },
        ],
      },
      {
        name: 'repeated section',
        repeat: true,
        padded: false,
        titles: [
          { y: 1, type: 'title', repeat: true },
          { y: 2, type: 'title', repeat: true },
          { y: 3, type: 'title', repeat: true },
          { y: 4, type: 'title', repeat: true },
        ],
        ads: [
          {
            y: 1,
            x: 0,
            classes: 'on_newtab_endless_native no-padding',
            props: {
              adType: 'native-outbrain',
              widgetId: 'AR_38',
              placement: 'on_newtab_endless_native',
              visibilityTimeoutInMs: 1,
            },
          },
          {
            y: 2,
            x: -1,
            classes: 'on_newtab_endless_right no-padding',
            props: {},
          },
          {
            y: 3,
            x: 2,
            classes: 'on_newtab_endless_native no-padding',
            props: {
              adType: 'native-outbrain',
              widgetId: 'AR_38',
              placement: 'on_newtab_endless_native',
              visibilityTimeoutInMs: 1,
            },
          },
          {
            y: 4,
            x: 1,
            classes: 'on_newtab_endless_right no-padding',
            props: {},
          },
        ],
      },
    ],
  },

  // aboveFoldSectionShown: 'LargeCardWithGridSection',
  aboveFoldSectionLeaderboardAd: 'on_testsection_leader',
  aboveFoldSectionAds: {
    // 2x3 layout ads (6 slots + leaderboard)
    grid3: [
      {
        y: 1,
        x: 1,
        classes: 'on_testsection_r1_c1',
      },
      {
        y: 1,
        x: 3,
        classes: 'on_testsection_r1_c3',
      },
    ],
    // 2x2 layout ads (4 slots)
    grid2: [
      {
        y: 1,
        x: 2,
        classes: 'on_testsection_r1_c2',
      },
      {
        y: 2,
        x: 1,
        classes: 'on_testsection_r2_c1',
      },
    ],
    // 2x1 layout ads (2 slots)
    grid1: [
      {
        y: 1,
        x: 1,
        classes: 'on_testsection_r1_c1_single',
      },
    ],
  },
}
