import type { OutbrainProps } from '../../common/components/AdOps/Outbrain.vue'

export const outbrainPlacementWidgets: Record<string, OutbrainProps> = {
  // Homepage placements
  on_newtab_r1_c2: {
    placement: 'on_newtab_r1_c2_ob_fallback',
    widgetId: 'AR_35',
    adHeight: 304,
    adWidth: 300,
  },
  on_newtab_r1_topright: {
    placement: 'on_newtab_r1_topright_ob_fallback',
    widgetId: 'AR_36',
    adHeight: 304,
    adWidth: 300,
  },
  on_newtab_r2_c1: {
    placement: 'on_newtab_r2_c1_ob_fallback',
    widgetId: 'AR_37',
    adHeight: 304,
    adWidth: 300,
  },
  on_newtab_r3_right: {
    placement: 'on_newtab_r3_right_ob_fallback',
    widgetId: 'AR_45',
    adHeight: 304,
    adWidth: 300,
  },
  // on_newtab_r4_c1: {
  //   placement: 'on_newtab_r4_c1_ob_fallback',
  //   widgetId: 'AR_44',
  //   adHeight: 304,
  //   adWidth: 300,
  // },
  on_newtab_endless_native: {
    placement: 'on_newtab_endless_native_ob_fallback',
    widgetId: 'AR_38',
    adHeight: 304,
    adWidth: 300,
  },
  on_newtab_endless_right: {
    placement: 'on_newtab_endless_right_ob_fallback',
    widgetId: 'AR_38',
    adHeight: 304,
    adWidth: 300,
  },
  on_carousel_1: {
    placement: 'on_carousel_1_ob_fallback',
    widgetId: 'AR_27',
    adHeight: 304,
    adWidth: 612,
  },
  on_carousel_2: {
    placement: 'on_carousel_2_ob_fallback',
    widgetId: 'AR_27',
    adHeight: 304,
    adWidth: 612,
  },
  // Article page placements
  on_article_siderail: {
    placement: 'on_article_siderail_ob_fallback',
    widgetId: 'SB_2', // Fallback to the 'on_article_siderail_native' placement widget
    adHeight: 600,
    adWidth: 300,
  },
}
