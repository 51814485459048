import type { LayoutSkeleton } from '../data-fetching/auto-layout/index.types'
import type {
  AboveFoldSectionAds,
  SectionComponents,
} from '../../components/Sections/types/index.types'
import { z } from 'zod'
import { layoutSkeletonSchema } from '../data-fetching/auto-layout/index.types'
import { aboveFoldSectionAdsSchema } from '../../components/Sections/types/index.types'

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>
}
// === Interfaces and Types ===

// Sponsored Tiles

export interface SponsoredTilesConfig {
  provider: 'affinity' | 'admarketplace' | 'off'
  tilesCount: number | 5
  design?: 'msn' | 'google'
  variant?: 'default' | 'circle' | 'large-primary' | 'large-secondary'
}

export type TilesProvider =
  | {
      provider: 'affinity' | 'admarketplace'
      tilesCount: number
    }
  | {
      provider: 'direct'
      name: string
      image_url: string
      click_url: string
      impression_url: string
    }

export interface DynamicSponsoredTiles {
  tilesProvider: TilesProvider[]
  design?: 'msn' | 'google'
  variant?: 'default' | 'circle' | 'large-primary' | 'large-secondary'
}

export interface DynamicFloorPricing {
  isEnabled: boolean
  excludePlacements?: Array<string>
}
// User Settings

export interface UserSettings {
  position: 'off' | 'footer'
  defaultSettings: {
    theme: 'light' | 'dark' | 'auto'
    articleSummary: boolean
  }
  enabledSettings: {
    articleSummary: boolean
  }
}

// Layout Structures

export interface ArticleGroupDetails {
  design: 'google' | 'msn'
  articlesPerGroup: number
  type: 'top-stories' | 'related-stories'
}

export interface SummaryPageSkeleton {
  design: 'google' | 'msn'
  siderail: {
    position?: 'below-header' | 'full-height'
    topClass: string
    middleClass: string
    bottomClass: string
    topArticleGroup?: ArticleGroupDetails
    bottomArticleGroup?: ArticleGroupDetails
  }
  content: {
    aboveTitleClass: string
    topClass: string
    belowImageClass: string
    middleClass: string
    bottomContentAboveButtonClass: string
    bottomContentBelowButtonClass: string
  }
  showArticleImage: boolean
  bottomClass: string
}

export interface HomePageSkeleton {
  doodle: 'off' | 'on' | 'random'
  design: 'google' | 'msn'
  searchboxDesign: 'default' | 'v2' | 'v2-big-yolo' | 'v3'
}

// Partitioned ContentDisplaySettings Interfaces

export interface AdSettings {
  sponsoredTiles?: SponsoredTilesConfig
  dynamicSponsoredTiles?: DynamicSponsoredTiles
  aboveFoldSectionAds?: AboveFoldSectionAds
  aboveFoldSectionLeaderboardAd?:
    | 'on_testsection_leader'
    | 'sn_testsection_leader'
    | 'gd_testsection_leader'
    | 'default_testsection_leader'
    | ''
  adsDynamicFloorPricing?: DynamicFloorPricing
}

export interface SiteSettings {
  userSettings?: UserSettings
  enableLocalNewsSearch?: boolean
  sortNewsBy?: 'publishedDate' | 'clusterSize'
  enableArticleReordering?: boolean
  videoEnabled?: boolean
}

export interface LayoutSettings {
  layoutSkeleton: LayoutSkeleton
  homePage?: DeepPartial<HomePageSkeleton>
  summaryPage?: DeepPartial<SummaryPageSkeleton>
}

export interface FeatureSplitFlags {
  ayData?: unknown
  timestamp?: string
  name?: string
  aboveFoldSectionShown?: keyof SectionComponents
}

// Final Combined Type

export type ContentDisplaySettings = AdSettings &
  SiteSettings &
  LayoutSettings &
  FeatureSplitFlags

// === Zod Schemas ===

// Sponsored Tiles

export const sponsoredTilesSchema = z.object({
  provider: z.union([
    z.literal('affinity'),
    z.literal('admarketplace'),
    z.literal('off'),
  ]),
  tilesCount: z.number().default(5),
  design: z.union([z.literal('msn'), z.literal('google')]).optional(),
  variant: z
    .union([
      z.literal('default'),
      z.literal('circle'),
      z.literal('large-primary'),
      z.literal('large-secondary'),
    ])
    .optional(),
})

// Tiles Providers
export const tilesProviderSchema = z.object({
  provider: z.union([z.literal('affinity'), z.literal('admarketplace')]),
  tilesCount: z.number().default(5),
})

export const directTilesProviderSchema = z.object({
  provider: z.literal('direct'),
  name: z.string(),
  image_url: z.string(),
  click_url: z.string(),
  impression_url: z.string(),
})

export const dynamicSponsoredTilesSchema = z.object({
  tilesProvider: z.array(
    z.union([tilesProviderSchema, directTilesProviderSchema])
  ),
  design: z.union([z.literal('msn'), z.literal('google')]).optional(),
  variant: z
    .union([
      z.literal('default'),
      z.literal('circle'),
      z.literal('large-primary'),
      z.literal('large-secondary'),
    ])
    .optional(),
})

export const dynamicFloorPricingSchema = z.object({
  isEnabled: z.boolean().default(true),
  excludePlacements: z.array(z.string()).optional().default([]),
})

// User Settings
export const userSettingsSchema = z.object({
  position: z
    .union([z.literal('off'), z.literal('footer'), z.literal('navbar')])
    .default('off')
    .transform((value) => {
      if (value === 'navbar') return 'footer'
      return value
    }),
  defaultSettings: z.object({
    theme: z.union([z.literal('light'), z.literal('dark'), z.literal('auto')]),
    articleSummary: z.boolean().default(true),
  }),
  enabledSettings: z
    .object({
      articleSummary: z.boolean().default(false),
    })
    .default({ articleSummary: false }),
})

// Home + Summary Layout
export const homePageSkeletonSchema = z.object({
  doodle: z.union([z.literal('off'), z.literal('on'), z.literal('random')]),
  design: z.optional(z.union([z.literal('google'), z.literal('msn')])),
  searchboxDesign: z.optional(
    z.union([
      z.literal('default'),
      z.literal('v2'),
      z.literal('v2-big-yolo'),
      z.literal('v3'),
    ])
  ),
})

export const articleGroupDetailsSchema = z.object({
  design: z.union([z.literal('google'), z.literal('msn')]).optional(),
  articlesPerGroup: z.number().optional().default(3),
  type: z
    .union([z.literal('top-stories'), z.literal('related-stories')])
    .optional()
    .default('top-stories'),
})

export const summaryPageSkeletonSchema = z.object({
  design: z.union([z.literal('google'), z.literal('msn')]).optional(),
  siderail: z
    .object({
      position: z
        .union([z.literal('below-header'), z.literal('full-height')])
        .optional(),
      topClass: z.string().optional(),
      middleClass: z.string().optional(),
      bottomClass: z.string().optional(),
      topArticleGroup: articleGroupDetailsSchema.optional(),
      bottomArticleGroup: articleGroupDetailsSchema.optional(),
    })
    .optional(),
  content: z
    .object({
      aboveTitleClass: z.string().optional(),
      topClass: z.string().optional(),
      belowImageClass: z.string().optional(),
      middleClass: z.string().optional(),
      bottomContentAboveButtonClass: z.string().optional(),
      bottomContentBelowButtonClass: z.string().optional(),
    })
    .optional(),
  showArticleImage: z.boolean().optional().default(true),
  bottomClass: z.string().optional(),
})

// === Partitioned ContentDisplaySettings Schema ===

export const adSettingsSchema = z.object({
  sponsoredTiles: z.optional(sponsoredTilesSchema),
  dynamicSponsoredTiles: z.optional(dynamicSponsoredTilesSchema),
  aboveFoldSectionAds: z.optional(aboveFoldSectionAdsSchema),
  aboveFoldSectionLeaderboardAd: z
    .union([
      z.literal('on_testsection_leader'),
      z.literal('sn_testsection_leader'),
      z.literal('gd_testsection_leader'),
      z.literal(''),
    ])
    .default(''),
  adsDynamicFloorPricing: z.optional(dynamicFloorPricingSchema),
})

export const siteSettingsSchema = z.object({
  userSettings: z.optional(userSettingsSchema),
  enableLocalNewsSearch: z.optional(z.boolean()).default(true),
  sortNewsBy: z
    .optional(z.union([z.literal('publishedDate'), z.literal('clusterSize')]))
    .default('publishedDate'),
  enableArticleReordering: z.optional(z.boolean()),
  videoEnabled: z.optional(z.boolean()),
})

export const layoutSettingsSchema = z.object({
  layoutSkeleton: layoutSkeletonSchema,
  homePage: z.optional(homePageSkeletonSchema.partial()),
  summaryPage: z.optional(summaryPageSkeletonSchema),
})

export const featureSplitFlagsSchema = z.object({
  ayData: z.unknown(),
  timestamp: z.optional(z.string()),
  name: z.optional(z.string()),
  aboveFoldSectionShown: z.optional(z.literal('LargeCardWithGridSection')),
})

// Unified ContentDisplaySettings Schema
export const contentDisplaySettingsSchema = adSettingsSchema
  .merge(siteSettingsSchema)
  .merge(layoutSettingsSchema)
  .merge(featureSplitFlagsSchema)
