import { render, h } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import Outbrain from '../../components/AdOps/Outbrain.vue'
import { getFallbackPlacementWidget } from '../../utils/ad-ops/adFunctions'
import loadScriptAsync from '../../utils/loadScriptAsync'

export interface OutbrainElementData {
  abtest: string
  element: Element
  idx: number
  name: string
  servedWidgetId: string
  timestamp: number
  version: string
  widgetId: string
}

export interface OBREvent {
  name: string
  widgetId: string
  feedCards: number
  callback: (args: OutbrainElementData) => void
}

export interface OBExtern {
  extern: {
    researchWidget: () => unknown
  }
}

export type OBR = OBExtern & Record<string, unknown>
declare global {
  interface Window {
    OBR: OBR
    OBREvents: OBREvent[]
  }
}

const useOutbrainAds = createSharedComposable(() => {
  const isLoadedPromise = loadScriptAsync(
    'https://widgets.outbrain.com/outbrain.js',
    {
      id: 'outbrain-homepage-header-script',
      key: 'outbrain-homepage-header-script',
      type: 'text/javascript',
      referrerpolicy: 'no-referrer-when-downgrade',
      async: true,
      'data-disableLocalStorageGdpr': 'true',
      onload: () => console.debug('Outbrain script loaded.'),
      onerror: () => console.error('Outbrain script failed to load.'),
    }
  )

  const callRenderResearchWidgets = async (): Promise<void> => {
    try {
      await isLoadedPromise

      if (
        !window.OBR ||
        !window.OBR.extern ||
        typeof window.OBR.extern.researchWidget !== 'function'
      ) {
        console.warn(
          'Outbrain research widget function not available after script load'
        )
        return
      }
      // Render ad for empty widgets.
      window.OBR.extern.researchWidget()
    } catch (err) {
      console.error(
        'Error when calling Outbrain research widget function.',
        err
      )
    }
  }

  // Renders an Outbrain ad in a placement div. Used for programmatic ad fallback.
  const renderFallbackOutbrainAd = (
    placement: string,
    element: Element
  ): void => {
    const widgetProps = getFallbackPlacementWidget(placement)

    if (!widgetProps) return

    const { vueApp } = useNuxtApp()
    const outbrainNode = h(Outbrain, widgetProps)
    outbrainNode.appContext = vueApp._context

    // Remove current placement class that it's attached to the programmatic ad to avoid rerender attempts
    element.classList.remove(placement)
    // Add the placement class related to the native ad
    element.classList.add(widgetProps.placement)
    // Erase the innerHTML of the placement div to clean scripts and other elements added by AY integration
    element.innerHTML = ''

    // Insert the outbrain ad wrapper into the placement div
    render(outbrainNode, element)

    // Call the Outbrain research widget function to render the ad
    callRenderResearchWidgets()
  }

  const triggerOutbrainRenderedEvent = async (
    widgetId: string,
    callbackFn: (args: OutbrainElementData) => void
  ): Promise<void> => {
    await isLoadedPromise
    if (!window.OBREvents) {
      window.OBREvents = []
      console.warn('Initialized empty OBREvents array after script load')
    }
    // Adds a listener to the widget rendered event.
    window.OBREvents.push({
      name: 'rendered',
      widgetId: widgetId,
      feedCards: 1,
      callback: callbackFn,
    })
  }

  return {
    callRenderResearchWidgets,
    triggerOutbrainRenderedEvent,
    renderFallbackOutbrainAd,
  }
})

export default useOutbrainAds
