const loadedScripts: Map<string, Promise<void>> = new Map()

/**
 * Safely and asynchronously loads a script into the document.
 * If the script is already loaded, it returns the existing promise.
 * If the script is not loaded, it creates a new script element and appends it to the document head.
 * The script is marked as loaded once it has been successfully appended to the document.
 * If the script fails to load, it rejects the promise with an error message.
 * @param src The URL of the script to load
 * @param options
 * @returns
 */
export function loadScriptAsync(
  src: string,
  options: Record<string, unknown> = {}
): Promise<void> {
  if (loadedScripts.has(src)) {
    return loadedScripts.get(src)!
  }
  loadedScripts.set(
    src,
    new Promise((resolve, reject) => {
      if (typeof document === 'undefined') {
        if (typeof useHead === 'function') {
          return useHead({
            script: [
              {
                src,
                type: 'text/javascript',
                async: true,
                onload: () => {
                  resolve()
                },
                onerror: () => {
                  reject(new Error(`Failed to load script: ${src}`))
                },
                ...options,
              },
            ],
          })
        } else {
          console.warn(
            'loadScriptAsync is called outside of the browser environment'
          )
          return reject(new Error('Document is not defined'))
        }
      }
      // Check if the script is already in the document
      const existingScript = document.querySelector(`script[src="${src}"]`)
      if (existingScript) {
        // If the script is already in the document, resolve immediately
        if (existingScript.hasAttribute('data-loaded')) {
          resolve() // Script is already loaded
          return
        } else {
          // If the script is not loaded, remove it and create a new one
          existingScript.remove()
        }
      }

      const script = document.createElement('script')
      script.src = src
      script.type = 'text/javascript'
      script.referrerPolicy = 'no-referrer-when-downgrade'
      script.onload = () => {
        script.setAttribute('data-loaded', 'true')
        resolve()
      }
      script.onerror = () => reject(new Error(`Failed to load script: ${src}`))
      document.head.appendChild(script)
    })
  )
  return loadedScripts.get(src)!
}

export default loadScriptAsync
