import { createSharedComposable } from '@vueuse/core'
import type { RevenueResponse } from '../../../server/services/bi-report-service/index.types'
import { useAnalytics } from '../../analytics/useAnalytics'

const CPMS_LOCAL_STORAGE_KEY = 'app:ad-cpms'

export interface LocalStorageCpmData {
  date: string
  prices: Record<string, RevenueResponse>
}

const getCurrentDateString = (): string =>
  new Date().toISOString().split('T')[0]

const getUtcHourOfDay = (): string =>
  new Date().getUTCHours().toString().padStart(2, '0')

const getDefaultCpm = (): number => {
  return Number(useAppConfig().defaultCpm)
}

const loadFromLocalStorage = (): LocalStorageCpmData | null => {
  try {
    const raw = localStorage.getItem(CPMS_LOCAL_STORAGE_KEY)
    if (!raw) return null
    const parsed: LocalStorageCpmData = JSON.parse(raw)
    return parsed.date === getCurrentDateString() ? parsed : null
  } catch (error) {
    console.error('Error reading stored data from localStorage:', error)
    localStorage.removeItem(CPMS_LOCAL_STORAGE_KEY)
    return null
  }
}

const saveToLocalStorage = (newData: LocalStorageCpmData): void => {
  try {
    localStorage.setItem(CPMS_LOCAL_STORAGE_KEY, JSON.stringify(newData))
  } catch (error) {
    console.error('Error saving data to localStorage:', error)
  }
}

const fetchPlacementData = async (): Promise<
  Record<string, RevenueResponse>
> => {
  const dateStr = getCurrentDateString()
  const url = `/api/v2/ad-placement/cpm?date=${dateStr}`

  return await $fetch<Record<string, RevenueResponse>>(url)
}

function getCachedCpm(
  data: LocalStorageCpmData | null,
  hourOfDay: string,
  placement: string
): number | undefined {
  if (!data) return undefined

  const cpmValue = data.prices?.[hourOfDay]?.cpms?.[placement]
  if (cpmValue == null) return undefined

  return Number(cpmValue)
}

function useCpmsRaw() {
  const cachedData = ref<LocalStorageCpmData | null>(null)
  const { track } = useAnalytics()

  function fallbackCpm(placement: string): number {
    track('NtpDefaultCpm_Used', {
      placement,
      country: window.ayManagerEnv?.geoInfo?.country ?? '',
    })
    return getDefaultCpm()
  }

  async function getHourlyCpmForPlacement(placement: string): Promise<number> {
    const hourOfDay = getUtcHourOfDay()

    const currentCache = cachedData.value || loadFromLocalStorage()
    if (currentCache) {
      cachedData.value = currentCache
      const cpmFromCache = getCachedCpm(currentCache, hourOfDay, placement)
      if (cpmFromCache !== undefined) return cpmFromCache
    }

    // fallback
    try {
      const newData = await fetchPlacementData()
      const updatedCache: LocalStorageCpmData = {
        date: getCurrentDateString(),
        prices: newData,
      }
      saveToLocalStorage(updatedCache)
      cachedData.value = updatedCache

      const cpmFromFetch = getCachedCpm(updatedCache, hourOfDay, placement)
      return cpmFromFetch !== undefined ? cpmFromFetch : fallbackCpm(placement)
    } catch {
      track('NtpDefaultCpm_Used', {
        placement: placement,
        country: window.ayManagerEnv?.geoInfo?.country ?? '',
      })
      return fallbackCpm(placement)
    }
  }

  return { getHourlyCpmForPlacement }
}
export const useCpms = createSharedComposable(useCpmsRaw)
